import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import {
  Button,
  LoadingContainer,
  LoadingIcon,
} from '@pandler/shared/react-ui';
import { type FormEventHandler, useState } from 'react';
import type {
  StripeError,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';

export interface PaymentFormProps {
  onError?: (error: StripeError) => void;
  returnUrl: string;
}

export function PaymentForm({ onError, returnUrl }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [ready, setReady] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    setIsLoading(false);

    console.log('result', result);

    if (result.error && onError) {
      onError(result.error);
    }
  };

  const handleOnChange = (event: StripePaymentElementChangeEvent) => {
    console.log('change', event);
    setIsFormValid(event.complete);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        onReady={() => setReady(true)}
        onChange={handleOnChange}
      />

      {!ready && <LoadingContainer className="mb-6" />}
      {ready && (
        <div className="mt-6 flex">
          <Button
            type="submit"
            className="w-full text-center flex justify-center"
            disabled={!isFormValid}
          >
            {isLoading ? <LoadingIcon className="w-5 h-5 text-white" /> : 'Pay'}
          </Button>
        </div>
      )}
    </form>
  );
}
