import { Routes, Route } from 'react-router-dom';
import { NotFoundPage, PortalLayout } from '@pandler/cloud/skeleton';

import { PayRoutes } from './pages/pay/routes';
import IndexPage from './index-page';
import { RootContainer } from './containers/root-container';
import { Header } from './components/header';

export function App() {
  return (
    <RootContainer>
      <Routes>
        <Route path="/" element={<PortalLayout Header={Header} />}>
          <Route index element={<IndexPage />} />
          <Route path="/pay/*" element={<PayRoutes />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </RootContainer>
  );
}

export default App;
