import { usePublicOrganization } from '@pandler/cloud/shared';
import {
  ErrorPage,
  ErrorScreen,
  LoadingScreen,
} from '@pandler/shared/react-ui';
import { type ReactNode } from 'react';

interface OrganizationContainerOptions {
  children: ReactNode | ReactNode;
  id: string;
}

export function OrganizationContainer({
  id,
  children,
}: OrganizationContainerOptions) {
  const { data, isLoading, isError } = usePublicOrganization({ id });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !data) {
    return (
      <ErrorPage
        hideLinks
        title="Not found"
        screen={
          <ErrorScreen
            title="Not found."
            errorCode="404 error"
            description="Sorry, we can’t find the page you’re looking for."
            hideIcon
          />
        }
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
