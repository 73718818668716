import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

export function PayReturnPage() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<undefined | string>();

  useEffect(() => {
    if (status) {
      return;
    }

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    setStatus(url.searchParams.get('redirect_status') ?? 'unknown');
  }, [id, navigate, status]);

  useEffect(() => {
    if (status) {
      navigate(
        `/pay/${id}/${status === 'succeeded' ? 'success' : `error/${status}`}`
      );
    }
  }, [navigate, status, id]);

  return (
    <Helmet>
      <title>loading...</title>
    </Helmet>
  );
}
