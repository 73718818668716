import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { type ReactNode, useMemo } from 'react';

export interface ContainerProps {
  secret: string;
  id: string;
  stripeAccount?: string;
  children: ReactNode;
}

export function Container({
  id,
  secret,
  children,
  stripeAccount,
}: ContainerProps) {
  const stripePromise = useMemo(
    () => loadStripe(id, { stripeAccount }),
    [id, stripeAccount]
  );

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: secret }}>
      {children}
    </Elements>
  );
}
