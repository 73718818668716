import { type ReactNode, useMemo } from 'react';

import { getPublicOrganizationId } from '../../../utils/get-public-organization-id';
import { ErrorPage, ErrorScreen } from '@pandler/shared/react-ui';
import { OrganizationContainer } from '../organization-container';
import { useGoogleAnalytics, useHighlight } from '@pandler/cloud/shared';

interface OrganizationContainerOptions {
  children: ReactNode | ReactNode;
}

export function RootContainer({ children }: OrganizationContainerOptions) {
  useGoogleAnalytics();
  useHighlight({ projectId: '2d12qqer' });

  const publicId = useMemo(
    () => getPublicOrganizationId(window.location.href),
    []
  );

  if (!publicId) {
    return (
      <ErrorPage
        hideLinks
        title="Not found"
        screen={
          <ErrorScreen
            title="Not found."
            errorCode="404 error"
            description="Sorry, we can’t find the page you’re looking for."
            hideIcon
          />
        }
      />
    );
  }

  return (
    <OrganizationContainer id={publicId}>{children}</OrganizationContainer>
  );
}
