import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { PayIndexPage } from './index-page';
import { PayErrorPage } from './error-page';
import { PayReturnPage } from './return-page';
import { PaySuccessPage } from './success-page';

export function PayRoutes() {
  return (
    <ReactRouterRoutes>
      <Route path="/:id" element={<PayIndexPage />} />
      <Route path="/:id/error/:status" element={<PayErrorPage />} />
      <Route path="/:id/return" element={<PayReturnPage />} />
      <Route path="/:id/success" element={<PaySuccessPage />} />
    </ReactRouterRoutes>
  );
}
