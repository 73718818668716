import type { PublicOrganization } from '@pandler/shared/data-types';
import { API_ENDPOINT } from '@pandler/shared/utils';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getPublicOrganizationId } from '../../utils/get-public-organization-id';

interface UseOrganizationOptions {
  queryOptions?: UseQueryOptions<PublicOrganization, Error, PublicOrganization>;
}

export default function useOrganization({
  queryOptions = {},
}: UseOrganizationOptions = {}) {
  const id = useMemo(
    () => getPublicOrganizationId(window.location.href) ?? '',
    []
  );

  return useQuery<PublicOrganization, Error>(
    ['public-organization', id],
    async () => {
      const $response = await fetch(
        `${API_ENDPOINT}/organization/public/${id}`,
        {
          headers: { accept: 'application/json' },
        }
      );

      const data = await $response.json();
      if (!data) {
        throw new Error('Unable to retrieve organization');
      }

      return data;
    },
    { staleTime: Infinity, retry: false, ...queryOptions }
  );
}
