import {
  CancelIcon,
  DoneIcon,
  ProcessingIcon,
  StripeIcon,
  WarningIcon,
} from '@pandler/shared/react-ui';
import type { PaymentIntent } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PaymentError } from './payment-error';

const isStatus = (status: unknown): status is PaymentIntent.Status =>
  typeof status === 'string' &&
  [
    'canceled',
    'processing',
    'requires_action',
    'requires_capture',
    'requires_confirmation',
    'requires_payment_method',
    'succeeded',
  ].includes(status);

export function PayErrorPage() {
  const { status = '' } = useParams();

  if (!isStatus(status)) {
    return (
      <>
        <Helmet>
          <title>Payment error</title>
        </Helmet>
        <PaymentError
          icon={StripeIcon}
          title="An unknown error occurred"
          message="We ran into an unknown error when processing your payment. If you believe this is a mistake, please contact support."
        />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Payment error</title>
      </Helmet>

      {status === 'succeeded' && (
        <PaymentError
          icon={DoneIcon}
          title="You've already paid"
          message="This invoice has already been paid. No further action is required."
        />
      )}

      {(status === 'requires_action' ||
        status === 'requires_capture' ||
        status === 'requires_confirmation' ||
        status === 'requires_payment_method') && (
        <PaymentError
          icon={WarningIcon}
          title="Unable to process payment"
          message="We were unable to automatically process your payment. If you believe this is an error, please contact support."
        />
      )}

      {status === 'processing' && (
        <PaymentError
          icon={ProcessingIcon}
          title="Your payment is processing"
          message="Please allow some time for your payment to be processed."
        />
      )}

      {status === 'canceled' && (
        <PaymentError
          icon={CancelIcon}
          title="Your payment has been cancelled"
          message="To continue, please crete a new payment."
        />
      )}
    </>
  );
}
