import { useEffect } from 'react';
import { H } from 'highlight.run';
import { useAuth0 } from '@auth0/auth0-react';

interface UseHighlightOptions {
  projectId: string;
}

export default function useHighlight({ projectId }: UseHighlightOptions) {
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    console.log('initialize highlight');

    H.init(projectId, {
      tracingOrigins: true,
      disableSessionRecording: true,
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [
          // insert full or partial urls that you don't want to record here
          // Out of the box, Highlight will not record these URLs (they can be safely removed):
          'https://www.googleapis.com/identitytoolkit',
          'https://securetoken.googleapis.com',
        ],
      },
    });
  }, [projectId]);

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      console.log('highlight: identify user');
      H.identify(user.email);
    }
  }, [isAuthenticated, user]);
}
