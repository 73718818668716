export function getPublicOrganizationId(url: string): string | undefined {
  const urlPattern = /^https?:\/\/([^.]+)\.[^.]+\.[^./]+\/?$/;

  // Extract publicId using regex pattern
  const matches = url.match(urlPattern);
  if (matches && matches[1]) {
    return matches[1];
  }

  // If no valid publicId found in URL, check for NX_PUBLIC_ORGANIZATION_ID environment variable
  const envPublicId = process.env['NX_PUBLIC_ORGANIZATION_ID'];
  if (envPublicId) {
    return envPublicId;
  }

  // If neither valid URL nor environment variable present, return undefined
  return undefined;
}
