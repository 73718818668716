import { Helmet } from 'react-helmet-async';

export function IndexPage() {
  return (
    <>
      <Helmet>
        <title>Index</title>
      </Helmet>

      <p>index page</p>
    </>
  );
}

export default IndexPage;
