import { EmptyState, HappyNewsIcon } from '@pandler/shared/react-ui';
import { Helmet } from 'react-helmet-async';

export function PaySuccessPage() {
  return (
    <>
      <Helmet>
        <title>Payment success</title>
      </Helmet>

      <EmptyState
        className="mt-12"
        iconClassName="h-52"
        titleClassName="text-slate-800 text-lg"
        bodyClassName="text-slate-500"
        icon={HappyNewsIcon}
        title="Thanks for your payment"
        body="Your payment has successfully been processed."
      />
    </>
  );
}
