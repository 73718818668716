import { Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { Sidebar } from '../../components/sidebar';
import { MobileSidebar } from '../../components/mobile-sidebar';
import { Header } from '../../components/header';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '@pandler/cloud/shared';
import { ReadyContainer } from '../../components/ready-container';
import { baseMenuItems, menuItems } from '../../config/menu';
import { LoadingContainer } from '@pandler/shared/react-ui';
import { RouteErrorBoundary } from '../../components/route-error-boundary';

export function MainLayout() {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const { isAuthenticated } = useAuth0();
  const { organization, hasOnboarded } = useOrganization();

  return (
    <>
      {!isAuthenticated && <Outlet />}
      {isAuthenticated && organization && (
        <div className="font-open-sans">
          {hasOnboarded && (
            <ReadyContainer>
              <MobileSidebar
                navigation={menuItems}
                isOpen={mobileSidebarOpen}
                setIsOpen={setMobileSidebarOpen}
              />

              <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 print:hidden">
                <Sidebar
                  navigation={
                    organization.onboarded ? menuItems : baseMenuItems
                  }
                />
              </div>
            </ReadyContainer>
          )}

          <div
            className={classNames(
              'flex flex-col flex-1 min-h-screen dark:bg-neutral-900',
              {
                'md:pl-64': hasOnboarded,
              }
            )}
          >
            {hasOnboarded && <Header setIsOpen={setMobileSidebarOpen} />}

            <main className="flex-1 flex flex-col">
              <RouteErrorBoundary>
                <Suspense fallback={<LoadingContainer />}>
                  <Outlet />
                </Suspense>
              </RouteErrorBoundary>
            </main>
          </div>
        </div>
      )}
    </>
  );
}
