import { Button, EmptyState } from '@pandler/shared/react-ui';
import type { ElementType } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface PaymentErrorProps {
  icon: ElementType;
  title: string;
  message: string;
}

export function PaymentError({ icon, title, message }: PaymentErrorProps) {
  const navigate = useNavigate();
  const { id = '' } = useParams();

  return (
    <div>
      <EmptyState
        className="mt-12"
        iconClassName="h-52"
        titleClassName="text-slate-800 text-lg"
        bodyClassName="text-slate-500"
        icon={icon}
        title={title}
        body={message}
      />
      {id && (
        <Button
          onClick={() => navigate(`/pay/${id}`)}
          className="mx-auto xl:w-64 w-full flex justify-center mt-6"
        >
          Back
        </Button>
      )}
    </div>
  );
}
