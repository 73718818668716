import { Helmet } from 'react-helmet-async';
import { useGetPaymentIntent } from '@pandler/cloud/shared';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ErrorScreen,
  Invoice,
  LoadingContainer,
} from '@pandler/shared/react-ui';
import useOrganization from '../../hooks/use-organization';
import { Container, PaymentForm } from '@pandler/shared/payment-ui';
import type { StripeError } from '@stripe/stripe-js';

export function PayIndexPage() {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data, isLoading, isError } = useGetPaymentIntent({ id });
  const { data: organization } = useOrganization();

  const handleOnError = (error: StripeError) => {
    if (error.payment_intent?.status) {
      const { status } = error.payment_intent;
      navigate(`/pay/${id}/error/${status}`);
    }
  };

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (isError || !data || !organization) {
    return (
      <ErrorScreen
        title="Something went wrong"
        description={
          <>
            <p className="mt-6 mb-1">
              Unfortunately we're unable to fetch your invoice.
            </p>
            <p>If you believe this is an error, please contact support.</p>
          </>
        }
        hideIcon
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Pay invoice</title>
      </Helmet>

      <div className="flex flex-row-reverse lg:flex-row lg:space-x-4 lg:space-y-0 space-y-4">
        <div className="w-full hidden lg:block lg:w-2/3">
          <Invoice
            invoice={data.invoice}
            customer={data.customer}
            company={{
              name: organization.name,
              logo: organization.logo,
              address: organization.address,
            }}
          />
        </div>
        <div className="w-full lg:w-1/3">
          <div className="p-4 sm:p-6 sm:pt-5 sm:pb-8 rounded shadow bg-white">
            <h2 className="text-base lg:text-xl font-semibold text-gray-900 mb-4">
              Pay with card
            </h2>

            <Container
              secret={data.intent.secret}
              id={process.env['NX_STRIPE_ID'] ?? ''}
              stripeAccount={data.intent.stripeId}
            >
              <PaymentForm
                returnUrl={`${window.location.protocol}//${window.location.host}${window.location.pathname}/return`}
                onError={handleOnError}
              />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
