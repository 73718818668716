import useOrganization from '../../hooks/use-organization';

export function Header() {
  const { data } = useOrganization();
  if (!data) {
    return null;
  }

  return (
    <nav className="sticky bg-white border-b border-slate-100 shadow-sm">
      <div className="container mx-auto py-4 px-4 lg:px-0 lg:py-6">
        {data.logo ? (
          <img src={data.logo} alt={data.name} className="h-6" />
        ) : (
          <span className="font-medium text-dark-900 text-xl">{data.name}</span>
        )}
      </div>
    </nav>
  );
}
